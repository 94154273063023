import {httpClient} from "@/httpClient";

function getArticlesByVin(vin: string) {
  return httpClient.get(`/api/rest/vin?vin=${vin}`).then(response => response.data)
}

function getCar(vin: string) {
  return httpClient.get(`/api/rest/vin/carInfo?vin=${vin}`).then(response => response.data)
}

function getGroups(carId: string, catalogId: string, criteria: string) {
  return httpClient.get(`/api/rest/vin/groups?carId=${carId}&catalogId=${catalogId}`).then(response => response.data)
}

function getParts(carId: string, catalogId: string, groupId: string) {
  return httpClient.get(`/api/rest/vin/parts?carId=${carId}&catalogId=${catalogId}&groupId=${groupId}`).then(response => response.data)
}

function getSubGroups(carId: string, catalogId: string, criteria: string, groupId: string) {
  return httpClient.get(`/api/rest/vin/subgroups?carId=${carId}&catalogId=${catalogId}&groupId=${groupId}`).then(response => response.data)
}


export default {
  getArticlesByVin,
  getCar,
  getParts,
  getGroups,
  getSubGroups
}
